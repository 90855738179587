import * as yup from 'yup';

export const breadCrumb = {
    Company: 'Mi empresa',
    Roles: 'Roles y permisos',
};

export const rolesAndPermissions = {
    Roles: 'Roles y permisos',
    NewRoles: 'Nuevo Rol',
};

export const roleList = {
    Roles: 'Mis roles',
    SubText: 'Aquí podrás ver tus roles actuales y los roles sugeridos predeterminados',
};

export const filterOptions = [
    {value: 3, label: 'Todos los roles'},
    {value: 1, label: 'Mis roles'},
    {value: 2, label: 'Sugeridos'},
];

export const roleTable = {
    Role: 'Rol',
    Access: 'Acceso en',
    Users: 'Cantidad de Usuarios',
    UserQuantity: 'Usuarios',
    Active: 'Activo',
    Error: 'Elige un nombre diferente.',
};

export const rolesData = [
    {id: 1, role: 'Super admin', access: 'Administrativo', userQuantity: 25, isActive: false},
    {id: 2, role: 'Supervisor', access: 'Operativo', userQuantity: 15, isActive: true},
    {id: 3, role: 'Coordinador', access: 'Administrativo', userQuantity: 50, isActive: true},
    {id: 4, role: 'Asistente', access: 'Operativo', userQuantity: 10, isActive: false},
    {id: 5, role: 'Jefe de Proyectos', access: 'Administrativo', userQuantity: 30, isActive: true},
    {id: 6, role: 'Operador (Sugerido)', access: 'Operativo', userQuantity: 10, isActive: false},
    {id: 7, role: 'Director(Sugerido)', access: 'Administrativo', userQuantity: 3, isActive: true},
    {id: 8, role: 'Técnico (Sugerido)', access: 'Operativo', userQuantity: 20, isActive: true},
    {
        id: 9,
        role: 'Analista (Sugerido)',
        access: 'Administrativo',
        userQuantity: 18,
        isActive: false,
    },
    {id: 10, role: 'Consultor (Sugerido)', access: 'Operativo', userQuantity: 12, isActive: true},
];

export const sideMenu = {
    Duplicate: 'Duplicar',
    Edit: 'Editar',
    ChangeName: 'Cambiar nombre',
    Delete: 'Eliminar',
};

export const deleteRoleMenu = {
    title: '¿Confirmas que deseas eliminar este rol?',
    text: [
        'Eliminar este rol <b>no se puede deshacer y afectará los permisos y accesos de los usuarios asociados.</b> ',
        'Por favor, confirma si estás seguro de continuar.',
    ],
    buttonText: 'Eliminar',
    Cancel: 'Cancelar',
};

export const disableMenu = (users: number) => {
    const content = {
        title: '¿Confirmas que deseas desactivar este rol?',
        text: [
            `Estás a punto de desactivar un rol asignado a ${users} de usuarios.`,
            '<b>Esto revocará sus permisos y accesos, afectando sus tareas. </b>',
            '¿Confirmas que deseas proceder?',
        ],
        buttonText: 'Desactivar',
    };

    return content;
};

export const rolTooltip = {
    Title: 'Rol Duplicado de Accesos',
    subTitle:
        'Este rol comparte los mismos accesos que otro en tu lista. Te recomendamos editar los permisos para optimizar tu experiencia.',
};

export const listsort = {
    Asc: 'asc',
    Desc: 'desc',
    Access: 'access',
    Role: 'role',
    UserQuantity: 'userQuantity',
};

export const listFilter = {
    AllRoles: 3,
    Suggested: 2,
    MyRoles: 1,
};

export const searchBar = {
    Placeholder: 'Busque nombre de rol',
};

export const superAdmin = {
    SuperAdmin: 'Super admin',
};

export const MAX_NAME_LENGTH = 25;

export const roleSchema = yup.object({
    name: yup
        .string()
        .required('Campo Obligatorio')
        .max(MAX_NAME_LENGTH, `Máximo ${MAX_NAME_LENGTH} caracteres`),
});
