export const CONFIRMPASS = {
    TITLE: 'Nueva contraseña',
    SUBTITLE: 'Introduce tu nueva contraseña',
    CONFIRM: 'Confirma la nueva contraseña',
};
export const MAILVALIDATE = {
    TITLE: 'Olvidé mi contraseña',
    SUBTITLE: 'Se enviará un enlace al correo electrónico proporcionado.',
};
export const MAILVALIDATESUCCESS = {
    TITLE: 'Verificación de cuenta',
    SUBTITLE: 'Le informamos que hemos enviado un correo electrónico a la dirección ',
    PROCESS:
        'Dentro de este correo, encontrará un enlace que le facilitará el proceso para cambiar su contraseña.',
};

export const MAIL_VALIDATE = {
    TITLE: 'Olvide mi contraseña',
    SUBTITLE: 'Se enviará un enlace al correo electrónico proporcionado.',
};
export const CONFIRM_PASSWORD = {
    TITLE: 'Nueva contraseña',
    SUBTITLE: 'Introduce tu nueva contraseña',
    CONFIRM: 'Confirma la nueva contraseña',
};
export const MAIL_VALIDATE_SUCCESS = {
    TITLE: 'Verificación de cuenta',
    SUBTITLE: 'Le informamos que hemos enviado un correo electrónico a la dirección ',
    PROCESS:
        'Dentro de este correo, encontrará un enlace que le facilitará el proceso para cambiar su contraseña.',
};

export const PASSWORD_EXPIRED = {
    Title: 'Enlace de recuperación de contraseña expirado',
    Text: 'Parece que el enlace que has seguido para recuperar tu contraseña ha expirado. Por tu seguridad, estos enlaces solo son válidos por un tiempo limitado.',
    Text_Button: 'Solicitar nuevo enlace',
    Text_Contact: 'Contactar servicio al cliente',
    Success_Message: 'El correo ha sido enviado, por favor revisa tu correo electrónico.',
};

export const CREATE_CLIENTS = {
    MANAGEMENT_ACCOUNT_FIRST: 'Gestiona tus préstamos,',
    MANAGEMENT_ACCOUNT_SECOND: 'compras y empeños',
    PAYMENT: 'Paga tus préstamos y empeños',
    ONE_CLICK: 'Paga en un click',
    LESS_COST: 'Se puntual en tus pagos y paga menos',
    OFFEERS: 'Recibe ofertas personalizadas',
    DISCOUNTS: 'Refiere amigos y consigue descuentos',
    CREATE_ACCOUNT: 'Crea tu cuenta',
    EXPERIENCE: '¡Personaliza tu experiencia, administra tus',
    EXPERIENDE_SECOND: 'préstamos, realiza pagos y más!',
    EMAIL: 'Correo o teléfono',
    OR: 'O',
    HAVE_ACCOUNT: 'Ya tengo una cuenta',
    LOGIN: 'Iniciar sesión',
    TERMS: 'Términos y condiciones de uso y el Aviso de privacidad de Ataskate.',
    CUSTOM: '¡Personaliza tu experiencia, administra tus',
    CUSTOM_SECOND: 'préstamos, realiza pagos y más!',
    EMAIL_ACCOUNT: 'Correo electrónico',
    NAME: 'Nombres',
    PATERNAL_SURNAME: 'Apellido paterno',
    MATERNAL_SURNAME: 'Apellido materno',
    PHONE: 'Teléfono',
    PASS: 'Contraseña',
    PASS_CONFIRM: 'Confirmar contraseña',
};

export const TYPE_OF_SCREEN = {
    CREATE_ACCOUNT: 1,
    CREATE_USER_EMAIL: 2,
};

export const SCHEMA_MESSAGE = {
    REQUIRED: 'Este campo es obligatorio',
    FULL_NAME_MAX:
        'El Nombre completo que esta tentando ingresar sobrepasó el limite de caracteres',
    PATERNAL_SURNAME_MAX:
        'El Apellido paterno que esta tentando ingresar sobrepasó el limite de caracteres',
    MATERNAL_SURNAME_MAX:
        'El NApellido materno que esta tentando ingresar sobrepasó el limite de caracteres',
    MIN_NAME_LENGTH: 'Entre 3 y 50 caracteres',
    PASSWORD: 'Debe incluir una mayúscula, una minúscula, un número y un carácter especial',
    PASSWORD_MIN: 'La contraseña debe tener al menos 8 caracteres.',
    PASSWORD_MAX: 'La contraseña debe tener maximo 50 caracteres.',
    PASSWORDS_MATCH: 'Las contraseñas deben coincidir',
    PHONE: 'El número de teléfono debe tener entre 10 dígitos',
    EMAIL_FORMAT: 'El formato de correo no se reconoce, intenta de nuevo. Ej. tunombre@correo.com',
    EMAIL_MAX: 'El correo que está intentando ingresar sobrepasó el límite de caracteres',
    REQUIRED_LADA: 'El campo lada es obligatorio',
};

export const EMAIL_ERROR = {
    CLOSE: 'close',
    MESSAGE_TEXT: 'Este correo ya está en uso, intenta con uno diferente o',
    MESSAGE_TEXT_CTA: 'Inicia sesión.',
    USER_VALIDATION: 'Encontramos tu información, ¿Eres ',
};

export const MIN_TIME_TOKEN_EXP: number = 5;
