import {AppDispatch} from '@/config/store';
import {refreshTokenAction} from '@/components/Login/Redux/Actions/GetValidateUsers';
import {MIN_TIME_TOKEN_EXP} from '@/components/Login/constants';

export const refreshToken = (
    newToken: string | null,
    dispatch: AppDispatch,
    currentToken: string
) => {
    if (
        newToken &&
        validateExpirationTime() &&
        validateNewTokenAndCurrentToken(newToken, currentToken)
    ) {
        dispatch(refreshTokenAction(newToken));
    }
};

const validateExpirationTime = (): boolean => {
    const tokeExpirationTime = new Date().getTime();
    const expirationTime = MIN_TIME_TOKEN_EXP * 60 * 1000;
    const currentTime = new Date().getTime();
    const isTokenExpired = currentTime - tokeExpirationTime > expirationTime;

    return isTokenExpired;
};

const validateNewTokenAndCurrentToken = (newToken: string, currentToken: string): boolean => {
    return newToken !== currentToken;
};
