import React from 'react';
import {header, newRole} from '@components/RolesPermissions/NewRole/constants';
import {ContainerFlex, Input, Text} from '@components/Shopify/Ecommerce/styles';
import {FieldError, useFormContext} from 'react-hook-form';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';

export type IChangeRoleName = {
    isEdit: boolean;
    error?: FieldError;
};

const ChangeRoleName = ({isEdit, error}: IChangeRoleName) => {
    const {register} = useFormContext();
    const selectedRole = useSelector((state: RootState) => state.GetRoleById.role);
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="white"
            Border="1px solid #E8E9EA"
            Radius="16px"
            Padding="20px"
            Gap="10px"
            Height="10rem"
            id={header.name}
        >
            <Text FontWeight="700" FontSize="1.2rem">
                {newRole.name}
            </Text>
            <Input
                Width="50%"
                {...register('name')}
                placeholder={isEdit && selectedRole.name}
                HolderColor="#4e4e4e"
                error={error?.type}
            />
            <Text FontSize=".9rem" MarginLeft="5px" Color={error?.type ? '#A82424' : '#2A2C2F'}>
                {error?.message || newRole.inputWarning}
            </Text>
        </ContainerFlex>
    );
};

export default ChangeRoleName;
