import {IconContainer} from '@/components/Quoter/styles';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {DinamicPadding} from '@/components/AuctionPass/AuctionConstants';
import {idSelected} from '@components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {Text} from '@components/Shopify/Ecommerce/styles';
import {TableItem} from '@DeadLines/styles';
import {TextEllipsis} from '@Shopify/Ecommerce/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ILocationTree, Level} from '@components/NewUserEmployee/interface';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {RootState} from '@/config/store';

export const TreeComponent = (data: ILocationTree) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: Level, index: number) => {
                    return (
                        <TreeNode
                            node={tree}
                            level={data.level + 1}
                            branchesId={data.branchesId}
                            setBranchesId={data.setBranchesId}
                            idLevel={data.idLevel}
                            setIdLevel={data.setIdLevel}
                            currentNode={data.currentNode}
                            setCurrentNode={data.setCurrentNode}
                            isBranch={data.isBranch}
                            key={index}
                            isExpanded={data.isExpanded}
                            setIsExpanded={data.setIsExpanded}
                            setExpandAll={data.setExpandAll}
                            expandAll={data.expandAll}
                        />
                    );
                })}
        </>
    );
};

const TreeNode = ({
    node,
    level,
    branchesId,
    setBranchesId,
    idLevel,
    setIdLevel,
    currentNode,
    setCurrentNode,
    isBranch,
    isExpanded,
    setIsExpanded,
    expandAll,
    setExpandAll,
}: {
    node: Level;
    level: number;
    branchesId: number[];
    setBranchesId: React.Dispatch<React.SetStateAction<number[]>>;
    idLevel: number[];
    setIdLevel: React.Dispatch<React.SetStateAction<number[]>>;
    currentNode: Level | null;
    setCurrentNode: React.Dispatch<React.SetStateAction<Level | null>>;
    isBranch?: boolean;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
    expandAll: boolean;
}) => {
    const dispatch = useDispatch();
    const toggleBranchId = (node: Level) => {
        if (node.idBranch) {
            const isNodeSelected = branchesId.includes(node.idBranch);
            let updatedBranchState = isNodeSelected
                ? branchesId.filter((id) => id !== node.idBranch)
                : [...branchesId, node.idBranch];
            let updatedLevelState = idLevel;
            updatedLevelState = toggleAllLevelChildren(
                node,
                isNodeSelected,
                updatedLevelState,
                updatedBranchState
            );
            updatedBranchState = toggleAllBranchChildren(
                node,
                isNodeSelected,
                updatedLevelState,
                updatedBranchState
            );
            const parentNode = findParentNode(node.idLevel, locationsTreeData);
            if (parentNode) {
                updatedLevelState = toggleFather(parentNode, updatedLevelState, updatedBranchState);
            }
            setBranchesId(updatedBranchState);
            setIdLevel(updatedLevelState);
        } else {
            const isNodeSelected = idLevel.includes(node.idLevel);
            let updatedLevelState = isNodeSelected
                ? idLevel.filter((id) => id !== node.idLevel)
                : [...idLevel, node.idLevel];
            let updatedBranchState = branchesId;
            updatedLevelState = toggleAllLevelChildren(
                node,
                isNodeSelected,
                updatedLevelState,
                updatedBranchState
            );
            updatedBranchState = toggleAllBranchChildren(
                node,
                isNodeSelected,
                updatedLevelState,
                updatedBranchState
            );
            const parentNode = findParentNode(node.idFather, locationsTreeData);
            if (parentNode) {
                updatedLevelState = toggleFather(parentNode, updatedLevelState, updatedBranchState);
            }
            setBranchesId(updatedBranchState);
            setIdLevel(updatedLevelState);
        }
    };
    const locationsTreeData = useSelector(
        (state: RootState) => state.getLevelTreeBranchReducer.data
    );

    const toggleAllLevelChildren = (
        childNode: Level,
        isNodeSelected: boolean,
        updatedIdLevel: number[],
        updatedBranchState: number[]
    ) => {
        if (childNode.nextLevel) {
            childNode.nextLevel.forEach((child) => {
                if (isNodeSelected) {
                    updatedIdLevel = updatedIdLevel.filter((id) => id !== child.idLevel);
                } else {
                    updatedIdLevel = [...updatedIdLevel, child.idLevel];
                }
                updatedIdLevel = toggleAllLevelChildren(
                    child,
                    isNodeSelected,
                    updatedIdLevel,
                    updatedBranchState
                );
            });
        }
        return updatedIdLevel;
    };

    const toggleAllBranchChildren = (
        childNode: Level,
        isNodeSelected: boolean,
        updatedIdLevel: number[],
        updatedBranchState: number[]
    ) => {
        if (childNode.branches) {
            childNode.branches.forEach((child) => {
                if (isNodeSelected) {
                    updatedBranchState = updatedBranchState.filter((id) => id !== child.idBranch);
                } else {
                    updatedBranchState = [...updatedBranchState, child.idBranch];
                }
                updatedBranchState = toggleAllBranchChildren(
                    child,
                    isNodeSelected,
                    updatedIdLevel,
                    updatedBranchState
                );
            });
        }
        if (childNode.nextLevel) {
            childNode.nextLevel.forEach((child) => {
                updatedBranchState = toggleAllBranchChildren(
                    child,
                    isNodeSelected,
                    updatedIdLevel,
                    updatedBranchState
                );
            });
        }
        return updatedBranchState;
    };

    const findParentNode = (id: number, treeData: Level[]): Level | undefined => {
        if (!Array.isArray(treeData)) {
            return undefined;
        }

        for (const node of treeData) {
            if (node.idLevel === id || node.idBranch === id) {
                return node;
            }

            if (node.nextLevel && node.nextLevel.length > 0) {
                const levelResult = findParentNode(id, node.nextLevel);
                if (levelResult) return levelResult;
            }
        }

        return undefined;
    };
    const countIdsInLevel = (node: Level, idLevel: number[]): {countLevel: number} => {
        let countLevel = 0;

        if (node.nextLevel)
            node.nextLevel.forEach((childNode) => {
                if (idLevel.includes(childNode.idLevel)) {
                    countLevel++;
                }
            });

        return {countLevel};
    };
    const {countLevel} = countIdsInLevel(node, idLevel);

    const toggleFather = (
        parentNode: Level,
        updatedIdLevel: number[],
        updatedBranchState: number[]
    ) => {
        const isParentSelected = updatedIdLevel.includes(parentNode.idLevel);
        const areNextLevelChildrenSelected = parentNode.nextLevel.some((child) =>
            updatedIdLevel.includes(child.idLevel)
        );
        const areBranchesChildrenSelected = parentNode.branches.some((child) =>
            updatedBranchState.includes(child.idBranch)
        );
        if (!isParentSelected && (areNextLevelChildrenSelected || areBranchesChildrenSelected)) {
            updatedIdLevel.push(parentNode.idLevel);
        } else if (
            isParentSelected &&
            !areNextLevelChildrenSelected &&
            !areBranchesChildrenSelected
        ) {
            updatedIdLevel = updatedIdLevel.filter((id) => id !== parentNode.idLevel);
        }
        if (parentNode.idFather) {
            const parent = findParentNode(parentNode.idFather, locationsTreeData);
            if (parent) {
                updatedIdLevel = toggleFather(parent, updatedIdLevel, updatedBranchState);
            }
        }
        return updatedIdLevel;
    };

    const [nextChild, setNextChild] = useState(false);

    const expandNode = (node: Level) => {
        setNextChild(true);
        if (node.nextLevel) {
            node.nextLevel.forEach((child) => {
                if (idLevel.includes(child.idLevel)) {
                    setNextChild(true);
                }
                expandNode(child);
            });
        }
    };

    const collapseNode = (node: Level) => {
        setNextChild(false);
        if (node.nextLevel) {
            node.nextLevel.forEach((child) => {
                collapseNode(child);
            });
        }
    };

    useEffect(() => {
        if (isExpanded && level === 1) {
            setNextChild(true);
        }
    }, [isExpanded]);

    useEffect(() => {
        if (expandAll) {
            expandNode(node);
        } else {
            collapseNode(node);
        }
    }, [expandAll]);

    return (
        <>
            <TableRowItems
                GridColumn="3fr .5fr .5fr"
                Cursor="auto"
                Width="100%"
                Padding="0 16px"
                Border=""
                Height="56px"
            >
                <TableItem
                    Justify="start"
                    PaddingLeft={DinamicPadding(level)}
                    Gap="15px"
                    onClick={() => {
                        dispatch(idSelected(node.idLevel));
                        setNextChild(!nextChild);
                    }}
                    CursorTable="pointer"
                    BorderTop="1px solid #E8E9EA"
                >
                    {!isBranch && (
                        <IconContainer
                            className="material-icons"
                            FontSize="1.5rem"
                            Color={'#5A5AFF'}
                            Justify="start"
                        >
                            {nextChild ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
                        </IconContainer>
                    )}

                    <TextEllipsis FontWeight="700" FontSize="0.875rem" title={node.levelName}>
                        {node.levelName || node.branchName}
                    </TextEllipsis>
                </TableItem>
                <TableItem Justify="end" BorderTop="1px solid #E8E9EA">
                    <Text FontSize="0.875rem" FontWeight="700">
                        {countLevel}/{node.nextLevelCount}
                    </Text>
                </TableItem>
                <TableItem Justify="end" BorderTop="1px solid #E8E9EA">
                    {!isBranch && (
                        <Checkbox
                            checked={idLevel.includes(node.idLevel)}
                            onChange={() => {
                                toggleBranchId(node);
                            }}
                        />
                    )}
                    {isBranch && (
                        <Checkbox
                            checked={branchesId.includes(node.idBranch)}
                            onChange={() => {
                                toggleBranchId(node);
                            }}
                        />
                    )}
                </TableItem>
            </TableRowItems>

            {nextChild && node.nextLevel && (
                <TreeComponent
                    data={node.nextLevel}
                    level={level}
                    branchesId={branchesId}
                    setBranchesId={setBranchesId}
                    idLevel={idLevel}
                    setCurrentNode={setCurrentNode}
                    currentNode={currentNode}
                    setIdLevel={setIdLevel}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    setExpandAll={setExpandAll}
                    expandAll={expandAll}
                />
            )}
            {nextChild && node.branches && (
                <TreeComponent
                    data={node.branches}
                    level={level}
                    branchesId={branchesId}
                    setBranchesId={setBranchesId}
                    idLevel={idLevel}
                    setCurrentNode={setCurrentNode}
                    currentNode={currentNode}
                    setIdLevel={setIdLevel}
                    isBranch={true}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    setExpandAll={setExpandAll}
                    expandAll={expandAll}
                />
            )}
        </>
    );
};
