import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {
    ContainerFlex,
    ContainerForm,
    ContainerImg,
    Input,
    Text,
    Image,
} from '@/components/Shopify/Ecommerce/styles';
import {ICreateUserMarket} from '@components/Login/MarketPlaceLogin/interface';
import {yupResolver} from '@hookform/resolvers/yup';
import {shemaCreateClient} from '@Login/MarketPlaceLogin/SchemaCreateMarket';
import {ErrorMessages} from '@Login/MarketPlaceLogin/ErrorMessages';
import {CREATE_CLIENTS} from '@components/Login/constants';
import {SearchInput} from '@/components/Customer/Styles';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import {ErrorPassword} from '@Login/MarketPlaceLogin/ErrorPasword';
import {ErrorPasswordRepeat} from '@Login/MarketPlaceLogin/ErrorMessageRepeat';
import {IconContainer} from '@/components/CashFlow/styles';
import {getScreenState, getUserData} from '@Login/Redux/Actions/ChangeScreen';
import {getEmailVerificationUsersMarket} from '@components/Login/MarketPlaceLogin/Redux/Actions/ValidateEmailForMarketPlace';
import {RootState} from '@/config/store';
import {IDataLoginMarketVerify} from '@components/Login/interfaces';
import {createUserMarketPlace} from '@Login/MarketPlaceLogin/Redux/Actions/CreateUserMarketPlace';
import {CoincidenceUser, ErrorMail} from '@components/Login/MarketPlaceLogin/ErrorEmail';
import {SelectLada} from '@/components/Quoter/CoownerBeneficiary/SelectLada';
import {IGetAllNations} from '@/components/Quoter/CoownerBeneficiary/interface';

export const CreateUserWithEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const EmailVerify: IDataLoginMarketVerify = useSelector(
        (state: RootState) => state.getUsersMarketValidation
    );
    const [nationSelected, setNationSelected] = useState<IGetAllNations | null>(null);
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        reset,
        control,
        formState: {errors},
    } = useForm<ICreateUserMarket>({
        resolver: yupResolver(shemaCreateClient),
        mode: 'onChange',
        defaultValues: {
            firstName: '',
            paternalSurname: '',
            maternalSurname: '',
            phoneNumber: '',
            email: '',
            password: '',
            repeatPassword: '',
        },
        context: {nationSelected},
    });
    const [viewPassword, setViewPassword] = useState(false);
    const password = watch('password');
    const repeatPassword = watch('repeatPassword');
    const onSubmit = (values: ICreateUserMarket) => {
        const {
            firstName,
            paternalSurname,
            maternalSurname,
            email,
            password,
            repeatPassword,
            phoneNumber,
        } = values;
        const PostUser = {
            userClientName: `${firstName} ${paternalSurname} ${maternalSurname}`,
            email,
            password,
            firstName,
            paternalLastName: paternalSurname,
            maternalLastName: maternalSurname,
            phoneNumber: phoneNumber.replace(/\D/g, ''),
            countryId: nationSelected?.countryId ?? 0,
        };
        const userData = {
            firstName,
            paternalSurname,
            maternalSurname,
            email,
            password,
            repeatPassword,
            phoneNumber,
        };
        dispatch(getUserData(userData));
        dispatch(createUserMarketPlace(PostUser, navigate));
    };
    useEffect(() => {
        reset({
            email: '',
            firstName: '',
            password: '',
            repeatPassword: '',
        });
    }, []);
    const emailValue = watch('email');
    const firstName = watch('firstName');
    const paternalSurname = watch('paternalSurname');
    const maternalSurname = watch('maternalSurname');
    const phoneNumber = watch('phoneNumber');
    const passwordValue = watch('password');
    const repeat = watch('repeatPassword');
    const memoizedValuesData = useMemo(
        () => ({
            email: emailValue,
            firstName,
            paternalSurname,
            maternalSurname,
            password: passwordValue,
            repeatPassword: repeat,
            phoneNumber,
        }),
        [emailValue, firstName, passwordValue, paternalSurname, maternalSurname]
    );
    useEffect(() => {
        if (
            memoizedValuesData.email ||
            memoizedValuesData.firstName ||
            memoizedValuesData.password ||
            memoizedValuesData.repeatPassword ||
            memoizedValuesData.maternalSurname ||
            memoizedValuesData.paternalSurname ||
            memoizedValuesData.phoneNumber
        ) {
            dispatch(getUserData(memoizedValuesData));
        }
    }, [memoizedValuesData]);
    useEffect(() => {
        if (!errors.email && emailValue) {
            dispatch(getEmailVerificationUsersMarket(emailValue));
        }
    }, [emailValue, errors.email, dispatch]);

    const formatPhoneNumber = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2');
    };

    return (
        <ContainerForm BackG="" onSubmit={handleSubmit(onSubmit)}>
            <ContainerFlex
                Radius="24px"
                backG="#FFFFFF"
                Padding="32px"
                Gap="24px"
                Width="25.438rem"
                FlexDir="column"
                Height="auto"
                Border="1px solid #0D166B"
            >
                <ContainerFlex Gap="4px" FlexDir="column" Height="auto">
                    <ContainerFlex Height="auto" Position="relative">
                        <ContainerFlex
                            Align="end"
                            Position="absolute"
                            PositionTop="32px"
                            Justify="start"
                        >
                            <IconContainer
                                FontSize="1.5rem"
                                className="material-icons"
                                Color="#5A5AFF"
                                Width="auto"
                                Height="auto"
                                onClick={() => dispatch(getScreenState(1))}
                            >
                                arrow_back
                            </IconContainer>
                        </ContainerFlex>
                    </ContainerFlex>
                    <Text FontSize="1.5rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.CREATE_ACCOUNT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    FlexDir="column"
                    Align="start"
                    Gap="8px"
                    Height="auto"
                >
                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.NAME}
                    </Text>
                    <Input
                        {...register('firstName')}
                        type="text"
                        error={errors.firstName?.message}
                        maxLength={50}
                        pattern="[^\d]*"
                    />
                    {errors.firstName?.message && (
                        <ErrorMessages message={errors.firstName.message} />
                    )}

                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.PATERNAL_SURNAME}
                    </Text>
                    <Input
                        {...register('paternalSurname')}
                        type="text"
                        error={errors.paternalSurname?.message}
                        maxLength={50}
                        pattern="[^\d]*"
                    />
                    {errors.paternalSurname?.message && (
                        <ErrorMessages message={errors.paternalSurname.message} />
                    )}

                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.MATERNAL_SURNAME}
                    </Text>
                    <Input
                        {...register('maternalSurname')}
                        type="text"
                        error={errors.maternalSurname?.message}
                        maxLength={50}
                        pattern="[^\d]*"
                    />
                    {errors.maternalSurname?.message && (
                        <ErrorMessages message={errors.maternalSurname.message} />
                    )}
                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.EMAIL_ACCOUNT}
                    </Text>
                    <Input
                        {...register('email')}
                        type="text"
                        error={errors.email?.message || EmailVerify.error}
                    />

                    {errors.email?.message && <ErrorMessages message={errors.email.message} />}
                    {errors.email?.message === undefined && EmailVerify.error && <ErrorMail />}
                    {EmailVerify.userData?.exist && <CoincidenceUser setValue={setValue} />}

                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.PHONE}
                    </Text>

                    <Controller
                        control={control}
                        name="phoneNumber"
                        render={({field}) => (
                            <ContainerFlex>
                                <SelectLada
                                    handleNationSelected={setNationSelected}
                                    withoutToken={true}
                                />
                                <Input
                                    RadiusTR="50px"
                                    RadiusBR="50px"
                                    Padding="12px"
                                    FontSize="0.875rem"
                                    Radius="0px"
                                    type="text"
                                    value={field.value}
                                    onChange={(e) =>
                                        field.onChange(formatPhoneNumber(e.target.value))
                                    }
                                    maxLength={14}
                                />
                            </ContainerFlex>
                        )}
                    />
                    {errors.phoneNumber?.message && (
                        <ErrorMessages message={errors.phoneNumber.message} />
                    )}

                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.PASS}
                    </Text>
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="90% 10%"
                        Margin="0 0 4px 0"
                        BoxShadowH=""
                        BoxShadowF=""
                        Padding="0 1rem"
                        BackG="#FFFFFF"
                        error={errors.password?.message}
                        Radius="32px"
                    >
                        <input
                            type={viewPassword ? 'Text' : 'Password'}
                            {...register('password')}
                        />
                        <ContainerImg
                            onClick={() => setViewPassword(!viewPassword)}
                            Cursor="pointer"
                        >
                            <Image
                                src={viewPassword ? VisibilityOn : VisibilityOff}
                                alt="icon-warning"
                                Cursor="pointer"
                            />
                        </ContainerImg>
                    </SearchInput>
                    {password.length > 0 && (
                        <ErrorPassword
                            error={errors.password?.message ? true : false}
                            password={password}
                        />
                    )}
                    {errors.password?.message && password.length === 0 && (
                        <ErrorMessages message={errors.password?.message} />
                    )}
                    <Text FontSize="1rem" FontFamily="Nunito" FontWeight="700">
                        {CREATE_CLIENTS.PASS_CONFIRM}
                    </Text>
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="90% 10%"
                        Margin="0 0 4px 0"
                        BoxShadowH=""
                        BoxShadowF=""
                        Padding="0 1rem"
                        BackG="#FFFFFF"
                        error={errors.repeatPassword?.message}
                        Radius="32px"
                    >
                        <input
                            type={viewPassword ? 'Text' : 'Password'}
                            {...register('repeatPassword')}
                        />
                        {repeatPassword.length > 0 && (
                            <ContainerImg
                                onClick={() => setViewPassword(!viewPassword)}
                                Cursor="pointer"
                            >
                                <Image
                                    src={viewPassword ? VisibilityOn : VisibilityOff}
                                    alt="icon-warning"
                                    Cursor="pointer"
                                />
                            </ContainerImg>
                        )}
                    </SearchInput>
                    {repeatPassword.length > 0 && (
                        <ErrorPasswordRepeat
                            error={errors.repeatPassword?.message ? true : false}
                            password={password}
                        />
                    )}
                    {errors.repeatPassword?.message && repeatPassword.length === 0 && (
                        <ErrorMessages message={errors.repeatPassword?.message} />
                    )}
                </ContainerFlex>
                <ButtonGeneral text="Crear cuenta" />
            </ContainerFlex>
        </ContainerForm>
    );
};
