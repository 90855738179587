import Axios from 'axios';
import Swal from 'sweetalert2';

import {logoutSesion} from '@/components/Login/Interceptor/reduxLogOut';
import {refreshToken} from '@/components/Login/Interceptor/lifeToken';
import {AppDispatch} from '@/config/store';

export const InterceptorApp = (dispatch: AppDispatch, currentToken: string) => {
    Axios.interceptors.response.use(
        (response) => {
            const {data} = response;
            refreshToken(data.token, dispatch, currentToken);
            return response;
        },
        (error) => {
            if (error.message.includes('401')) {
                logoutSesion(dispatch);
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#ffbd00',
                    title: 'Lo sentimos, su sesión ha caducado.',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });
            }
            return Promise.reject(error);
        }
    );
};
