import React, {useEffect} from 'react';
import {Modal, Overlay} from '@/components/CashFlowActions/Operational/styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {RolesCompany} from '@/components/Users/UserProfile/Redux/Actions/GetRolCompanyAction';
import {
    FilterProps,
    IFilterFormValues,
    OptionType,
    RolCompany,
} from '@/components/MyCompanyUser/Operational/interfaceType';
import FilterForm from '@components/MyCompanyUser/ModalFilterForm';

export const ModalFilter: React.FC<FilterProps> = ({
    isOpen,
    closeModal,
    applyFilters,
    reset,
    control,
    errors,
    handleSubmit,
}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const companyId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.companyId
    );
    const rolesCompany = useSelector((state: RootState) => state.getRoleCompany.userData);

    useEffect(() => {
        dispatch(RolesCompany(token, companyId));
    }, [token, companyId, dispatch]);

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    const handleClose = () => {
        reset({
            Rol: '',
            Access: '',
            State: '',
        });
        closeModal();
    };

    const onSubmit = (data: IFilterFormValues) => {
        const roleOptions: OptionType[] = rolesCompany.map((role: RolCompany) => ({
            value: role.id.toString(),
            label: role.roleName,
        }));

        const selectedRole = roleOptions.find((role: OptionType) => role.value === data.Rol);
        const adjustedData = {
            Rol: data.Rol,
            Access: data.Access,
            State: data.State,
            roleId: parseInt(data.Rol, 10) || 0,
            RolLabel: selectedRole ? selectedRole.label : '',
        };

        applyFilters(adjustedData);
        closeModal();
    };

    const roleOptions: OptionType[] = rolesCompany?.map((role: RolCompany) => ({
        value: role.id.toString(),
        label: role.roleName,
    }));

    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Radius="16px 0px 0px 16px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Padding="24px"
                >
                    <FilterForm
                        control={control}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        handleClose={handleClose}
                        roleOptions={roleOptions}
                    />
                </ContainerFlex>
            </Modal>
        </>
    );
};

export default ModalFilter;
