import * as yup from 'yup';
import {SCHEMA_MESSAGE} from '@/components/Login/constants';

const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]:;"'<>,.?/~`|\\]).+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^(?:\(\d{2}\) \d{4,5}-\d{4}|[0-9]{10})$/;

export const shemaCreateClient = yup.object().shape({
    email: yup
        .string()
        .required(SCHEMA_MESSAGE.REQUIRED)
        .max(50, SCHEMA_MESSAGE.EMAIL_MAX)
        .matches(emailRegex, SCHEMA_MESSAGE.EMAIL_FORMAT),
    firstName: yup
        .string()
        .required(SCHEMA_MESSAGE.REQUIRED)
        .min(3, SCHEMA_MESSAGE.MIN_NAME_LENGTH)
        .max(50, SCHEMA_MESSAGE.FULL_NAME_MAX),
    paternalSurname: yup
        .string()
        .required(SCHEMA_MESSAGE.REQUIRED)
        .min(3, SCHEMA_MESSAGE.MIN_NAME_LENGTH)
        .max(50, SCHEMA_MESSAGE.PATERNAL_SURNAME_MAX),
    maternalSurname: yup
        .string()
        .required(SCHEMA_MESSAGE.REQUIRED)
        .min(3, SCHEMA_MESSAGE.MIN_NAME_LENGTH)
        .max(50, SCHEMA_MESSAGE.MATERNAL_SURNAME_MAX),
    phoneNumber: yup
        .string()
        .required(SCHEMA_MESSAGE.REQUIRED)
        .matches(phoneRegex, SCHEMA_MESSAGE.PHONE),
    password: yup
        .string()
        .required(SCHEMA_MESSAGE.REQUIRED)
        .min(8, SCHEMA_MESSAGE.PASSWORD_MIN)
        .max(50, SCHEMA_MESSAGE.PASSWORD_MAX)
        .matches(regex, SCHEMA_MESSAGE.PASSWORD),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], SCHEMA_MESSAGE.PASSWORDS_MATCH)
        .required(SCHEMA_MESSAGE.REQUIRED),
});
