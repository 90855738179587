import React, {useState, useEffect} from 'react';
import {ContainerCellGrid, ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {OPTION_TABLE, TEXT_NEW_USER} from '@/components/NewUserEmployee/Operational/constants';
import Warning from '@/images/IconeWarningBlue.svg';
import arrows from '@/images/ArrowsInvert.svg';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';

import {IAssignedRoleProps, IRolItem} from './Operational/interface';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {getRolByCompany} from '@components/NewUserEmployee/Redux/Actions/GetRolByCompanyActions';
import ModalRoles from '@/components/NewUserEmployee/MoldaRoles';
import {allRoleOption} from '@/components/Users/UserProfile/Redux/Actions/GetAllRoleAction';
import GetAllModulesAction from '@/components/RolesPermissions/Redux/Actions/GetAllModulesAction';

export const AssignedRole: React.FC<IAssignedRoleProps> = ({roles, setRoles}) => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });

    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const [selectedRoles, setSelectedRoles] = useState<{[key: number]: boolean}>({});
    const [selectionCount, setSelectionCount] = useState(0);
    const handleSwitchChange = (rowIndex: number, currentState: boolean) => {
        if (!currentState && selectionCount >= 3) {
            return;
        }

        setSelectedRoles((prev) => ({
            ...prev,
            [rowIndex]: !currentState,
        }));

        setSelectionCount((prev) => (!currentState ? prev + 1 : prev - 1));
    };
    useEffect(() => {
        if (token) {
            dispatch(getRolByCompany(token, {companyId}));
        }
    }, [token]);
    const rolesData = useSelector((state: RootState) => state.getRolByCompanyReducer.data);
    useEffect(() => {
        const rolesArray = rolesData.map((rol) => {
            return {
                ...rol,
                isSelected: false,
            };
        });
        setRoles(rolesArray);
    }, [rolesData]);
    const updateRoleSelection = (id: number) => {
        const updatedRoles = roles.map((rol) => {
            if (rol.id === id) {
                return {...rol, isSelected: !rol.isSelected};
            }
            return rol;
        });
        setRoles(updatedRoles);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState<IRolItem | null>(null);
    const handleRoleClick = async (role: IRolItem) => {
        try {
            await dispatch(allRoleOption(token, role.id, role));
            setSelectedRole(role);
            setIsModalOpen(true);
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if (token) dispatch(GetAllModulesAction(3, token));
    }, []);
    return (
        <>
            <ContainerFlex
                FlexDir="column"
                Height=""
                Gap="16px"
                Radius="16px"
                Border="1px solid #E8E9EA"
                Padding="24px"
            >
                <ContainerFlex Height="max-content" Justify="start" Align="start" FlexDir="column">
                    <Text FontSize="1.25rem" FontWeight="600" Color="#2A2C2F">
                        {TEXT_NEW_USER.ASSIGNMENT_Role}
                    </Text>
                    <Text>{TEXT_NEW_USER.ROLE_USER_ASSIGNMENT}</Text>
                </ContainerFlex>

                <ContainerFlex backG="#DBF4FF" Padding="16px" Radius="8px">
                    <ContainerCellGrid Gap="8px">
                        <Image width="24px" height="24px" src={Warning} alt="warning" />
                        <Text FontWeight="700" Color="#004461">
                            {TEXT_NEW_USER.WARNING_ROL}
                        </Text>
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex FlexDir="column">
                    <Table>
                        <TableRowHead
                            BackG="none"
                            GridColumn="1fr 1fr 76px;"
                            Height=""
                            BorderB="0.5px solid #e4e7e9"
                            Border=""
                            Radius=""
                            Padding="16px"
                        >
                            {OPTION_TABLE.map((option, index) => (
                                <TableItem key={index} Justify="start">
                                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                                        {option.name}
                                    </Text>
                                    <Image src={arrows} alt="arrows" />
                                </TableItem>
                            ))}
                        </TableRowHead>
                        <TableItemsContainer>
                            {roles.map((rol, rowIndex) => (
                                <TableRowItems
                                    key={rowIndex}
                                    GridColumn="1fr 1fr 76px;"
                                    BorderB="0.5px solid #e4e7e9"
                                    Border=""
                                    Height="max-content"
                                    Padding="4px 16px 4px 16px"
                                    BorderRadius=""
                                >
                                    <TableItem Justify="start">
                                        <Text
                                            onClick={() => handleRoleClick(rol)}
                                            Cursor="pointer"
                                            FontFamily="Nunito"
                                            FontWeight="700"
                                            FontSize="0.875rem"
                                            Color="#5A5AFF"
                                        >
                                            {rol.roleName}
                                        </Text>
                                    </TableItem>
                                    <TableItem Justify="start">
                                        <Text
                                            FontFamily="Nunito"
                                            FontWeight="400"
                                            FontSize="0.875rem"
                                            Color="#2A2C2F"
                                        >
                                            {rol.access}
                                        </Text>
                                    </TableItem>

                                    <TableItem Justify="start" Gap="24px">
                                        <GreenSwitch
                                            checked={rol.isSelected}
                                            onChange={() => {
                                                handleSwitchChange(
                                                    rowIndex,
                                                    selectedRoles[rowIndex] || false
                                                );
                                                updateRoleSelection(rol.id);
                                            }}
                                            disabled={
                                                selectionCount >= 3 && !selectedRoles[rowIndex]
                                            }
                                        />
                                    </TableItem>
                                </TableRowItems>
                            ))}
                        </TableItemsContainer>
                    </Table>
                </ContainerFlex>
            </ContainerFlex>

            {isModalOpen && (
                <ModalRoles
                    isOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    selectedRole={selectedRole}
                />
            )}
        </>
    );
};
