import React, {useRef, useState, useEffect} from 'react';
import {
    ContainerFlex,
    ContainerInputImage,
    InputFile,
    Modal,
    Text,
} from '@/components/Shopify/Ecommerce/styles';
import uploadImg from '@images/UploadFile.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {BUTTONS, ERRORS, MODAL_TEXT} from '@/components/Users/constants';
import {UploadProps} from '@/components/Users/UserProfile/interfaces';
import {ImageEditor} from '@/components/Users/UserProfile/imageEditor';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {addPictureUser} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/AddNewPicureUserAction';
import {Img} from '@/components/CashFlow/FundingRequest/styles';
import add from '@/images/addImgUser.svg';

const ImgInputUpload: React.FC<UploadProps> = ({isOpen, closeModal, onSave}) => {
    const dispatch = useDispatch();
    const fileUploadRef = useRef<HTMLInputElement | null>(null);
    const [avatarURL, setAvatarURL] = useState<string>(uploadImg);
    const [hasImage, setHasImage] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const userId = useSelector((state: RootState) => state.UserProfileAdmin.selectedEmployId);
    const [fileExtension, setFileExtension] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');

    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const VALID_EXTENSIONS = ['jpg', 'jpeg', 'png'];
    const VALID_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

    const getFileExtension = (fileName: string): string => {
        const ext = fileName.split('.').pop()?.toLowerCase() || '';
        return VALID_EXTENSIONS.includes(ext) ? (ext === 'jpeg' ? 'jpg' : ext) : '';
    };

    const validateImage = (file: File): boolean => {
        if (!VALID_MIME_TYPES.includes(file.type)) {
            setError(ERRORS.ERROR_TEXT);
            return false;
        }
        const extension = getFileExtension(file.name);
        if (!extension) {
            setError(ERRORS.ERROR_TEXT);
            return false;
        }

        if (file.size > MAX_FILE_SIZE) {
            setError(ERRORS.ERROR_TEXT);
            return false;
        }

        setError('');
        return true;
    };

    const handleImageUpload = () => {
        fileUploadRef.current?.click();
    };

    const handleDivClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        handleImageUpload();
    };

    const loadImageToEditor = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const result = e.target?.result;
            if (result && typeof result === 'string') {
                const img = new Image();
                img.src = result;
                img.onload = () => {
                    if (img.width === 0 || img.height === 0) {
                        setError(ERRORS.ERROR_TEXT);
                        return;
                    }
                    setImageElement(img);
                    setAvatarURL(result);
                    setHasImage(true);
                    setIsEditing(true);
                };

                img.onerror = () => {
                    setError(ERRORS.ERROR_TEXT);
                };
            }
        };

        reader.onerror = () => {
            setError(ERRORS.ERROR_TEXT);
        };

        try {
            reader.readAsDataURL(file);
        } catch (error) {
            setError(ERRORS.ERROR_TEXT);
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            setError(ERRORS.NEW);
            return;
        }

        const uploadedFile = files[0];
        if (validateImage(uploadedFile)) {
            const extension = getFileExtension(uploadedFile.name);
            setFileExtension(extension);
            setFileName(uploadedFile.name);
            loadImageToEditor(uploadedFile);
        }
    };

    const handleSave = () => {
        if (!imageElement) {
            setError(ERRORS.NEW);
            return;
        }

        try {
            const canvas = document.querySelector('canvas');
            if (!canvas) {
                throw new Error('Canvas not found');
            }
            const editedImageUrl = canvas.toDataURL('image/jpeg', 0.8);
            const base64Content = editedImageUrl.split(',')[1];
            const data = {
                fileExtension: fileExtension,
                fileContent: base64Content,
                userId: userId,
                fileName: fileName,
            };
            dispatch(addPictureUser(token, data));
            setAvatarURL(editedImageUrl);
            setIsEditing(false);
            if (onSave) onSave(editedImageUrl);
            closeModal();
        } catch (err) {
            setError(ERRORS.RESELECT);
        }
    };

    useEffect(() => {
        return () => {
            if (avatarURL !== uploadImg) {
                URL.revokeObjectURL(avatarURL);
            }
        };
    }, [avatarURL]);

    return (
        <Modal>
            <ContainerFlex
                FlexDir="column"
                Width="661px"
                Height="460px"
                Radius="24px"
                backG="#fff"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                Padding="24px"
            >
                <ContainerFlex FlexDir="column" Justify="start" Gap="24px">
                    <ContainerFlex FlexDir="column" Height="auto" Color="#1D1E20" Align="start">
                        <Text FontSize="1.25rem" FontWeight="600">
                            {MODAL_TEXT.ADD}
                        </Text>
                        <Text FontWeight="400">{MODAL_TEXT.ADD_INDICATION}</Text>
                        {error && (
                            <Text Color="red" FontSize="0.875rem">
                                {error}
                            </Text>
                        )}
                    </ContainerFlex>

                    <ContainerInputImage>
                        <InputFile
                            type="file"
                            ref={fileUploadRef}
                            onChange={handleFileSelect}
                            hidden
                            accept=".jpg,.jpeg,.png"
                        />

                        {!isEditing ? (
                            <ContainerFlex
                                Border="2px dashed blue"
                                Height="244px"
                                Width="613px"
                                FlexDir="column"
                                Cursor="pointer"
                                onClick={handleDivClick}
                                Justify="center"
                                Align="center"
                                Radius="16px"
                                Gap="4px"
                            >
                                <Img src={add} alt="add-icon" />
                                <Text FontSize="1rem" FontWeight="400">
                                    {MODAL_TEXT.ADD_FILE}
                                </Text>
                                {!hasImage && (
                                    <>
                                        <Text>o</Text>
                                        <ButtonGenerals>{MODAL_TEXT.BUTTON}</ButtonGenerals>
                                    </>
                                )}
                            </ContainerFlex>
                        ) : (
                            imageElement && (
                                <ImageEditor
                                    imageElement={imageElement}
                                    onReplace={handleImageUpload}
                                    onSave={handleSave}
                                />
                            )
                        )}
                    </ContainerInputImage>

                    <ContainerFlex Gap="24px">
                        <ButtonGenerals SecondaryButton="#FFFFFF" onClick={closeModal}>
                            {BUTTONS.CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals onClick={handleSave}>{BUTTONS.KEEP}</ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};

export default ImgInputUpload;
